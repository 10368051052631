<template class="m-0">
    <div>
        <div class="positionGeneralDiv">
            <div class="positionDiv">
                <section>
                    <div class="row positionGenralImg">
                        <v-img
                            class="imgSet"
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="175"
                            max-width="100%"
                            src="@/assets/login-img.jpg"
                        ></v-img>
                    </div>

                    <h1>
                        Seja bem vindo<br />
                        <span>à nossa plataforma!</span>
                    </h1>

                    <div class="favIconDiv"></div>

                    <v-form
                        :lazy-validation="true"
                        class="divForm"
                        v-model="valid"
                        ref="form"
                        v-if="!token"
                    >
                        <v-text-field
                            outlined
                            dense
                            class="custom-input"
                            label="Digite seu Email"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            required
                            :validate-on-blur="true"
                        ></v-text-field>

                        <v-layout>
                            <v-btn
                                block
                                elevation="0"
                                @click="sendAskToken"
                                class="text-capitalize teal accent-4 white--text"
                                :disabled="!valid"
                                >Recuperar Senha</v-btn
                            >
                        </v-layout>
                        <Loader
                            class="text-center mt-3"
                            v-if="askPasswordResetRequestStatus === RequestStatusEnum.START"
                            mode="overlay"
                        />
                    </v-form>
                    <v-form
                        :lazy-validation="true"
                        class="divForm"
                        v-model="valid"
                        ref="form"
                        v-else
                    >
                        <v-text-field
                            outlined
                            dense
                            class="custom-input"
                            label="Digite seu Email"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            required
                            :validate-on-blur="true"
                        ></v-text-field>
                        <v-text-field
                            label="Digite a senha"
                            v-model="password"
                            min="8"
                            outlined
                            dense
                            class="custom-input"
                            :append-icon="showPwdField ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPwdField ? 'text' : 'password'"
                            :rules="[rules.required]"
                            @click:append="showPwdField = !showPwdField"
                            required
                            :validate-on-blur="true"
                        ></v-text-field>

                        <v-text-field
                            label="Confirmar Senha"
                            min="8"
                            outlined
                            dense
                            class="custom-input"
                            :append-icon="showPwdField ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPwdField ? 'text' : 'password'"
                            :rules="[rules.required, rules.confirm]"
                            @click:append="showPwdField = !showPwdField"
                            required
                            :validate-on-blur="true"
                        ></v-text-field>

                        <v-layout>
                            <v-btn
                                block
                                elevation="0"
                                @click="resetPassword"
                                class="text-capitalize teal accent-4 white--text"
                                :disabled="!valid"
                                >Criar Senha</v-btn
                            >
                        </v-layout>
                        <Loader
                            class="text-center mt-3"
                            v-if="passwordRecoveryRequestStatus === RequestStatusEnum.START"
                            mode="overlay"
                        />
                    </v-form>
                </section>

                <v-layout justify-center class="footer">
                    <span> © 2021 Farm Investimentos. Todos os direitos reservados. </span>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email as emailValidator } from '@/helpers/validators';
import { notification } from '@/helpers/notification';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';

export default {
    name: 'RecuperarSenha',
    components: {
        Loader,
    },
    data: () => ({
        valid: false,
        form: null,
        email: '',
        password: '',
        showPwdField: false,
        RequestStatusEnum,
    }),
    computed: {
        ...mapGetters({
            askPasswordResetRequestStatus: 'auth/askPasswordResetRequestStatus',
            passwordRecoveryRequestStatus: 'auth/passwordRecoveryRequestStatus',
        }),
        token() {
            return this.$route.query.token;
        },
        rules() {
            return {
                required: value => !!value || 'Campo obrigatório',
                confirm: value => value === this.password || 'Senhas não conferem',
                email: v => emailValidator(v) || 'E-mail inválido',
            };
        },
    },
    watch: {
        askPasswordResetRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'E-mail enviado com sucesso!');
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Erro ao enviar e-mail, por favor tente novamente'
                );
                return RequestStatusEnum.ERROR;
            }
        },
        passwordRecoveryRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Senha atualizada com sucesso!');
                this.$router.push('/login');

                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Erro ao atualizar a senha, por favor tente novamente'
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    methods: {
        ...mapActions({
            askToken: 'auth/askToken',
            passwordRecovery: 'auth/passwordRecovery',
        }),
        sendAskToken() {
            if (this.$refs.form.validate()) {
                this.askToken(this.email);
            }
        },
        resetPassword() {
            if (this.$refs.form.validate()) {
                const payload = {
                    password: this.password,
                    token: this.token,
                    email: this.email,
                };
                this.passwordRecovery(payload);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import './Login.scss';
</style>
